import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/ja";
import SEO from "../components/seo";
import "../style/member-benefit.css";
import { Tabs } from "antd";
import $ from "jquery";

const MemberBenefitPage = (props) => {
  useEffect(() => {
    expandData();
  }, []);

  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState("benefit-all");
  const [activeKeyMobile, setMobileActiveKey] = useState("benefit-mobile-all");

  const expandData = () => {
    $(".benefit__mobile label").on("click", function () {
      $(this).toggleClass("benefit__tab--active");
    });
  };

  const specific_date = new Date("2022-01-09");
  const current_date = new Date();

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px auto
        }
        .benefit__special .table--benefit {
          display: inline-table
        }
      `,
        }}></style>
      <Layout location={props.location}>
        <SEO
          title="More benefits as you grow your membership status "
          keyword="ayana member benefits, benefits for member, ayana rewards"
          decription="Become an AYANA Rewards member and you will receive more benefits as you stay. As you grow your membership level, you will receive more as well. Become a member now and start to earn points!"
        />
        {current_date.getTime() > specific_date.getTime() ? (
          <>
            <div id="contentWrap">
              <div id="index_content" className="admin-setting">
                <div className="benefit__banner">&nbsp;</div>
                <div className="benefit">
                  <div className="benefit__banner__mobile mobile--only">
                    <img
                      className="full-width"
                      src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                    />
                  </div>
                  <div className="benefit__content">
                    <div className="container container--600">
                      <h1 className="text--center">メンバー特典</h1>
                      <p className="text--center">
                        アヤナリワードでは3つのレベルの会員を設けており、どのレベルでもお得な特典やサービスをお楽しみいただけます。
                        利用すれば利用するほど嬉しい特典が得られます。ご滞在ごとに充実したひとときをお楽しみください。
                      </p>
                    </div>
                    <div className="container container--1000">
                      <table className="table table--benefit table--benefit--main">
                        <thead>
                          <tr>
                            <th valign="middle">プログラムの概要</th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                              />
                              シルバー
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                              />
                              ゴールド
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                              />
                              プラチナ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              会員ランクをアップグレードするために必要な最小ポイント
                            </td>
                            <td>0</td>
                            <td>5,000</td>
                            <td>10,000</td>
                          </tr>
                          <tr>
                            <td>
                              会員ランクを維持するために毎年必要な最小ポイント
                            </td>
                            <td>0</td>
                            <td>1,500</td>
                            <td>5,000</td>
                          </tr>
                          <tr>
                            <td>ポイントの有効期限</td>
                            <td>10 年</td>
                            <td>10 年</td>
                            <td>10 年</td>
                          </tr>
                          <tr>
                            <td>ランクの有効期限</td>
                            <td>1 年</td>
                            <td>1 年</td>
                            <td>1 年</td>
                          </tr>
                          <tr className="table--benefit--subheader">
                            <td colspan="4">ポイント獲得特典</td>
                          </tr>
                          <tr>
                            <td>IDR10,000利用毎の獲得ポイント数</td>
                            <td>1 ポイント</td>
                            <td>1 ポイント</td>
                            <td>1 ポイント</td>
                          </tr>
                          <tr>
                            <td>
                              IDR15,000利用毎の獲得ポイント数（デロニックスのみ）
                            </td>
                            <td>1 ポイント</td>
                            <td>1 ポイント</td>
                            <td>1 ポイント</td>
                          </tr>
                          <tr>
                            <td>対象となる宿泊でポイントを獲得</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>飲食のご利用でポイントを獲得</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>スパトリートメントのご利用でポイントを獲得</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr className="table--benefit--subheader">
                            <td colspan="4">会員特典</td>
                          </tr>
                          <tr>
                            <td>会員限定の宿泊費割引</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>客室の無料１ランクアップグレード*</td>
                            <td>&nbsp;</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              対象となる料金の支払い時にボーナスポイントを獲得
                            </td>
                            <td>&nbsp;</td>
                            <td>10%</td>
                            <td>25%</td>
                          </tr>
                          <tr>
                            <td>アーリーチェックイン*</td>
                            <td>&nbsp;</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>レイトチェックアウト*</td>
                            <td>&nbsp;</td>
                            <td>2:00 PM</td>
                            <td>4:00 PM</td>
                          </tr>
                          <tr>
                            <td>ウェルネスプログラム特典**</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>誕生日に滞在する際の無料ギフト**</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>フラワースタジオでの会員限定割引</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>カワノグループレストランでの会員限定割引</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                        </tbody>
                      </table>
                      <small className="mart--lg" style={{ display: "block" }}>
                        *客室の空き状況によりご利用可能
                        <br />
                        **宿泊施設によって異なります
                        <br />
                        <Link className="link" to="/ja/terms-and-conditions-8">
                          詳細につきましては、利用規約をご参照ください。
                        </Link>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div id="contentWrap">
              <div id="index_content" className="admin-setting">
                <div className="benefit__banner"></div>
                <div className="benefit">
                  <div className="benefit__banner__mobile mobile--only">
                    <img
                      className="full-width"
                      src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                    />
                  </div>
                  <div className="benefit__content">
                    <div className="container container--600">
                      <h1 className="text--center">メンバー特典</h1>
                      <p className="text--center">
                        アヤナリワードでは3つのレベルの会員を設けており、どのレベルでもお得な特典やサービスをお楽しみいただけます。
                        利用すれば利用するほど嬉しい特典が得られます。ご滞在ごとに充実したひとときをお楽しみください。
                      </p>
                    </div>
                    <div className="container container--1000">
                      <table className="table table--benefit table--benefit--main">
                        <thead>
                          <tr>
                            <th valign="middle">プログラムの概要</th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                              />
                              シルバー
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                              />
                              ゴールド
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                              />
                              プラチナ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              メンバーシップのアップグレードに必要な最低ポイント
                            </td>
                            <td>0</td>
                            <td>5,000</td>
                            <td>10,000</td>
                          </tr>
                          <tr>
                            <td>IDR10,000ルピアご利用毎の獲得ポイント数*</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td>追加ボーナスポイント</td>
                            <td>&nbsp;</td>
                            <td>10%</td>
                            <td>15%</td>
                          </tr>
                          <tr>
                            <td>メンバー専用客室割引(ayanarewards.com)</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>フラワースタジオでの会員様限定割引</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>
                              カワノグループレストランにてメンバー限定割引
                            </td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>お誕生日期間にご滞在時にギフト贈呈</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="benefit__special">
                      <h1>施設別スペシャル特典</h1>
                      <div className="container container--1000">
                        <Tabs
                          className="benefit__tab"
                          defaultActiveKey="benefit-all"
                          size="large"
                          activeKey={activeKey}
                          onChange={(activeKey) => setActiveKey(activeKey)}>
                          <TabPane tab="バリ島ホテル" key="benefit-all">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">バリの全てのホテル</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    シルバー
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    ゴールド
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    プラチナ
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>ワンランク上に客室無料アップグレード*</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>スパトリートメントの割引</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>アーリーチェックイン*</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>レイトチェックアウト*</td>
                                  <td>&nbsp;</td>
                                  <td>4 PM</td>
                                  <td>6 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane
                            tab="アヤナミッドプラザ・ジャカルタ"
                            key="benefit-ayana">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">バリの全てのホテル</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    シルバー
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    ゴールド
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    プラチナ
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>ワンランク上に客室無料アップグレード*</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    無料のクラブラウンジへのアクセスと特典
                                  </td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>飲食施設にてポイント獲得</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Link to="/ja/preferred-hotels-resorts">
                                      IPreferリワード証明書のポイントを交換する
                                    </Link>
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>アーリーチェックイン*</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>レイトチェックアウト*</td>
                                  <td>2 PM</td>
                                  <td>4 PM</td>
                                  <td>6 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane
                            tab="デロニクスホテル・カラワン"
                            key="benefit-delonix">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">バリの全てのホテル</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    シルバー
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    ゴールド
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    プラチナ
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    マイアミカフェでのお食事ディスカウント
                                  </td>
                                  <td>10%</td>
                                  <td>15%</td>
                                  <td>20%</td>
                                </tr>
                                <tr>
                                  <td>ビジネスセンターのご利用を割引き</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                </tr>
                                <tr>
                                  <td>空港送迎を割引き</td>
                                  <td>20%</td>
                                  <td>20%</td>
                                  <td>25%</td>
                                </tr>
                                <tr>
                                  <td>フットサル場のご利用1時間を無料</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>ワンランク上に客室無料アップグレード*</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>飲食施設にてポイント獲得</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>アーリーチェックイン*</td>
                                  <td></td>
                                  <td>1 PM</td>
                                  <td>1 PM</td>
                                </tr>
                                <tr>
                                  <td>レイトチェックアウト*</td>
                                  <td></td>
                                  <td>4 PM</td>
                                  <td>4 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane
                            tab="アヤナ コモドリゾート"
                            key="benefit-komodo">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">バリの全てのホテル</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    シルバー
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    ゴールド
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    プラチナ
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>ランドツアーの割引</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>
                                    ウォータースポーツアクティビティー器具の割引
                                  </td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>スパトリートメントの割引</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>ボートトリップの割引</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>
                        </Tabs>

                        <small
                          className="mart--lg"
                          style={{ display: "block" }}>
                          *特典内容は、ホテルによって異なります。
                          <br />
                          {activeKey == "benefit-all" && (
                            <>
                              <span className="">
                                *スパ オンザ ロックは、対象外となります
                              </span>
                              <br />
                            </>
                          )}
                          {activeKey == "benefit-komodo" && (
                            <>
                              <span className="">
                                *アヤナ ラコ
                                ディア号でのクルージング旅行とボートチャーターは割引対象外となります
                              </span>
                              <br />
                            </>
                          )}
                          <Link
                            className="link"
                            to="/ja/terms-and-conditions-8">
                            詳細につきましては、利用規約をご参照ください。
                          </Link>
                        </small>
                      </div>
                    </div>
                    <div className="benefit__mobile">
                      <h1>施設別スペシャル特典</h1>
                      <div className="container container--1000">
                        <label
                          className="benefit__tab--active"
                          htmlFor="benefit-mobile-all">
                          バリ島ホテル
                        </label>
                        <input
                          defaultChecked={true}
                          id="benefit-mobile-all"
                          name="benefit"
                          type="checkbox"
                          onClick={() =>
                            setMobileActiveKey("benefit-mobile-all")
                          }
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">バリの全てのホテル</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                シルバー
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                ゴールド
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                プラチナ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>ワンランク上に客室無料アップグレード*</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>スパトリートメントの割引</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>アーリーチェックイン*</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>レイトチェックアウト*</td>
                              <td>&nbsp;</td>
                              <td>4 PM</td>
                              <td>6 PM</td>
                            </tr>
                          </tbody>
                        </table>
                        <label htmlFor="benefit-mobile-ayana">
                          アヤナミッドプラザ・ジャカルタ
                        </label>
                        <input
                          id="benefit-mobile-ayana"
                          name="benefit"
                          type="checkbox"
                          onClick={() =>
                            setMobileActiveKey("benefit-mobile-ayana")
                          }
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">バリの全てのホテル</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                シルバー
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                ゴールド
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                プラチナ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>ワンランク上に客室無料アップグレード*</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>無料のクラブラウンジへのアクセスと特典</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>飲食施設にてポイント獲得</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>アーリーチェックイン*</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>レイトチェックアウト*</td>
                              <td>2 PM</td>
                              <td>4 PM</td>
                              <td>6 PM</td>
                            </tr>
                          </tbody>
                        </table>
                        <label htmlFor="benefit-mobile-delonix">
                          デロニクスホテル・カラワン
                        </label>
                        <input
                          id="benefit-mobile-delonix"
                          name="benefit"
                          type="checkbox"
                          onClick={() =>
                            setMobileActiveKey("benefit-mobile-delonix")
                          }
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">バリの全てのホテル</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                シルバー
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                ゴールド
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                プラチナ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                誕生日にマイアミカフェで割引を利用しましょう
                              </td>
                              <td>20%</td>
                              <td>20%</td>
                              <td>20%</td>
                            </tr>
                            <tr>
                              <td>ビジネスセンターのご利用を割引き</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>10%</td>
                            </tr>
                            <tr>
                              <td>空港送迎を割引き</td>
                              <td>20%</td>
                              <td>20%</td>
                              <td>25%</td>
                            </tr>
                            <tr>
                              <td>フットサル場のご利用1時間を無料</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>ワンランク上に客室無料アップグレード*</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>飲食施設にてポイント獲得</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>アーリーチェックイン*</td>
                              <td></td>
                              <td>1 PM</td>
                              <td>1 PM</td>
                            </tr>
                            <tr>
                              <td>レイトチェックアウト*</td>
                              <td></td>
                              <td>4 PM</td>
                              <td>4 PM</td>
                            </tr>
                          </tbody>
                        </table>
                        <label htmlFor="benefit-mobile-komodo">
                          アヤナ コモドリゾート
                        </label>
                        <input
                          id="benefit-mobile-komodo"
                          name="benefit"
                          type="checkbox"
                          onClick={() =>
                            setMobileActiveKey("benefit-mobile-komodo")
                          }
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">バリの全てのホテル</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                シルバー
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                ゴールド
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                プラチナ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>ランドツアーの割引</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>
                                ウォータースポーツアクティビティー器具の割引
                              </td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>スパトリートメントの割引</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>ボートトリップの割引</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                          </tbody>
                        </table>
                        <small
                          className="mart--lg"
                          style={{ display: "block" }}>
                          *特典内容は、ホテルによって異なります。
                          <br />
                          {activeKeyMobile == "benefit-mobile-all" && (
                            <>
                              <span className="">
                                *スパ オンザ ロックは、対象外となります
                              </span>
                              <br />
                            </>
                          )}
                          {activeKeyMobile == "benefit-mobile-komodo" && (
                            <>
                              <span className="">
                                *アヤナ ラコ
                                ディア号でのクルージング旅行とボートチャーターは割引対象外となります
                              </span>
                              <br />
                            </>
                          )}
                          <Link
                            className="link"
                            to="/ja/terms-and-conditions-8">
                            詳細につきましては、利用規約をご参照ください。
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default MemberBenefitPage;
